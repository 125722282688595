@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Poppins:wght@100;300;400;500;600;700&family=Roboto+Mono:wght@400;700&display=swap");

body {
  background-color: #141414 !important;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-top: env(safe-area-inset-top);
}

@supports (padding: max(0px)) {
  body {
    padding-top: max(20px, env(safe-area-inset-top));
  }
}

html,
body,
#root,
.App,
.layout-container {
  height: 100%;
}

.canvas-wrapper {
  height: 100% !important;
  width: 100%;
}

.canvas-wrapper canvas {
  height: 100% !important;
  width: 100% !important;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-outlet {
  flex: 1;
}

header {
  height: 110px;
  min-height: 110px;
  opacity: 0;
  animation: fadeIn 2s forwards 2s;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  align-items: center;
}

.icons {
  font-size: 2.5em;
  display: flex;
  gap: 10px;
}
.carousel .icons {
  font-size: 2em;
  display: flex;
  gap: 10px;
}

.icons a {
  text-decoration: none;
  color: white;
}

.logo svg {
  height: 100px;
  width: auto;
}

.logo {
  display: inline-block;
}

svg {
  display: flex;
  justify-content: center;
}

.silhouette-animation {
  animation: silhouette-dash 5s ease-in forwards;
  stroke-dasharray: 1841;
  transition: all ease-in 0.5s;
  fill: transparent;
  &:hover {
    fill: black;
  }
  &__fill {
    stroke: white;
    &:hover {
      stroke: #000e8e;
    }
  }
}

.silhouette svg {
  max-height: 150px;
}

@keyframes silhouette-dash {
  0% {
    stroke-dashoffset: 1841;
    fill: transparent;
  }
  60% {
    stroke-dashoffset: 1841;
    fill: transparent;
  }
  94% {
    fill: transparent;
    stroke-dashoffset: 0;
  }
  100% {
    fill: black;
  }
}

footer {
  height: 110px;
  min-height: 110px;
}

.footer-content {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.icons svg {
  transition: ease-in 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}

.heading {
  color: white;
  font-family: "Poppins", sans-serif;
  z-index: 20;
}

.canvas-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.carousel-caption {
  background: linear-gradient(transparent, #000e8e 20%);
  color: #fff !important;
  font-family: "Poppins", sans-serif;
  bottom: 20px !important;
  position: relative !important;
  right: 0;
  left: 0 !important;
  border-radius: 0 0 5px 5px;
  padding-top: 22px !important;
  padding-bottom: 1rem !important;
  width: 100%;
}

.carousel-indicators [data-bs-target] {
  background-color: #ffffff !important;
  margin: 10px 2px 10px 2px;
}

.carousel-indicators {
  bottom: -55px !important;
}

.main-heading {
  animation: fadeIn 2s forwards;
  opacity: 0;
}

.subheading {
  animation: fadeIn 2s forwards 2s;
  opacity: 0;
}

.about {
  animation: fadeIn 2s forwards 8s;
  opacity: 0;
}

.projects {
  animation: fadeIn 2s forwards 8s;
  opacity: 0;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards 4s;
}
.arrow-move {
  animation: bounce 2s infinite;
}

.hand-wave {
  animation: wave 1s infinite;
  transform-origin: right center;
  transform: rotate(10deg);
  animation-timing-function: linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.w-90 {
  width: 90%;
}

.white {
  color: white;
}

img {
  border-radius: 5px;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.logo-name > div:first-child {
  height: 50px !important;
}

.globe-container {
  min-height: 150px;
  animation: fadeIn 2s forwards 2s;
  opacity: 0;
  margin-top: 20px;
}

.name-container {
  animation: fadeIn 2s forwards 5s;
  opacity: 0;
  margin-top: 20px;
}

footer svg {
  text-decoration: none;
  color: #fff;
  font-size: 2em;
}

@media (min-width: 600px) {
  .logo svg {
    height: 130px;
    width: auto;
  }
}
@media (min-width: 800px) {
}
@media (min-width: 1200px) {
}
